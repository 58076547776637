











































































































































































































































import axios from "axios";
import { defineComponent, ref } from "@vue/composition-api";
import { omitBy, isNil } from "lodash-es";
import { ValidationObserver } from "vee-validate";
import LoadingButton from "@/shared/components/LoadingButton";
import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import { HOST } from "@/constants";

export default defineComponent({
  name: "CeNewsletterRegistration",
  components: {
    ValidationObserver,
    LoadingButton,
  },
  props: {
    layout: {
      type: String,
    },
    listId: {
      type: String,
    },
    templateId: {
      type: String,
    },
    header: {
      type: String,
    },
    headerLayout: {
      type: String,
    },
    subheader: {
      type: String,
    },
    input: {
      type: String,
    },
  },
  setup(props) {
    const privacyUrl = "/datenschutz";
    const observerRef = ref<any>();
    let isSubmitting = ref(false);
    let errored = ref(false);
    let succeeded = ref(false);

    const formPostURL = `${HOST}/newsletterregistration/api`;

    const formData = ref({
      action: "REGISTER",
      data: {
        listId: props.listId,
        templateId: props.templateId,
        salutation: null,
        firstname: null,
        name: null,
        email: null,
        customer: null,
      },
    });

    const options = ref(["Frau", "Herr"]);
    const customerOptions: BaseRadioButtonProps[] = [
      {
        name: "isCustomer",
        value: true,
        title: "Ja",
      },
      {
        name: "isNoCustomer",
        value: false,
        title: "Nein",
      },
    ];

    async function handleFormSubmit() {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      isSubmitting.value = true;

      // Strip Null Values from data object to prevent backend FU
      const postData = {
        ...formData.value,
        data: omitBy(formData.value.data, isNil),
      };

      try {
        await axios
          .post(formPostURL, postData)
          .then(() => {
            errored.value = false;
            succeeded.value = true;
          })
          .catch(() => {
            succeeded.value = false;
            errored.value = true;
          });
      } catch {
        succeeded.value = false;
        errored.value = true;
      }

      isSubmitting.value = false;
      observerRef.value!.reset();
    }

    return {
      formData,
      observerRef,
      isSubmitting,
      privacyUrl,
      errored,
      succeeded,
      handleFormSubmit,
      options,
      customerOptions,
    };
  },
});
